<template>
  <div>
    <v-dialog v-model="dialog_open" persistent max-width="600px">
      <v-card>
        <v-card-title>Login</v-card-title>
        <v-card-text>
          <p>Please sign in to use the Timeful app.</p>
          <v-form @submit="login">
            <v-text-field label="Email" v-model="email"></v-text-field>
            <v-text-field
              label="Password"
              type="password"
              v-model="password"
            ></v-text-field>
            <v-btn type="submit">Login</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { auth } from "../schmucklicloud";

export default {
  data() {
    return {
      dialog_open: false,

      email: "",
      password: "",
    };
  },
  mounted() {
    if (this.getSessionToken() === "") {
      this.dialog_open = true;
    } else {
        this.$router.replace("/d");
    }
  },
  methods: {
    async login(e) {
      e.preventDefault();
      var response = await auth.authorizeEmailPassword(
        this.email,
        this.password
      );
      if (response.isOK) {
        this.setSessionToken(response.data.session_token);
        this.$notify.toast("Signed in successfully.");
        setTimeout(() => { window.location.reload() }, 1000);
      } else {
        this.$notify.toast(response.message);
      }
    },
    getSessionToken() {
      return window.localStorage.getItem("_session_token") || "";
    },
    setSessionToken(token) {
      window.localStorage.setItem("_session_token", token);
    },
  },
};
</script>

<style>
</style>